import React, { Component } from 'react';
//import WheelComponent from "react-wheel-of-prizes";
import WheelComponent from "./WheelComponent";
import instance from "./axios-instance";
import Swal from 'sweetalert2'
import { isMobile } from 'react-device-detect';
import Systems from "./SystemFunction";
import jwt_decode from "jwt-decode";

class App extends Component {
    constructor(props) {
        super(props)
        this.state = {
            categorylist: [],
            rewardlist: [],
            lock: "5 บาท",
            point: 0,
            user_id: "",
            disable: false,
            segColors: null,
            segments: null
            // segments: [{ id: 1, name: '5 บาท' }, { id: 2, name: '100 บาท' }, { id: 3, name: '300 บาท' }, { id: 4, name: '10 บาท' }, { id: 5, name: '20 บาท' }, { id: 6, name: 'คร้งหน้านะ บาท' }, { id: 7, name: 'เกือบได้แล้ว' }, { id: 8, name: 'ครั้งหน้านะ' }, { id: 9, name: '1000 บาท' }],
        }

    }
    componentDidMount() {
        
        this.checklogin();
        this.getwheel();
    }
    checklogin() {
        var token = localStorage.getItem('auth_token');
        if (token != null) {
            var decoded = jwt_decode(token);
            this.setState({
                user_id: decoded.message.playerid,
                point: decoded.message.Point,
                disable: true,
                loginstate: true,
            });
        }
        else {
            this.setState({
                loginstate: false,
            });
        }
    }

    getwheel = async () => {
        // var segments = [{ id: 1, name: '5 บาท' }, { id: 2, name: '100 บาท' }, { id: 3, name: '300 บาท' }, { id: 4, name: '10 บาท' }, { id: 5, name: '20 บาท' }, { id: 6, name: 'คร้งหน้านะ บาท' }, { id: 7, name: 'เกือบได้แล้ว' }, { id: 8, name: 'ครั้งหน้านะ' }, { id: 9, name: '1000 บาท' }];
        try {
            await instance.post("/api/v1/getwheelspin", {
                System: Systems,
            }).then((res) => {
                if (res.data.status === 200) {
                    const datas = res.data.message;
                    var segments = []
                    var segColors = []
                    for (var i = 0; i < datas.length; i++) {
                        var id = datas[i].id
                        var name = datas[i].description
                        var color = datas[i].color
                        var setp = { id, name }
                        segments.push(setp)
                        segColors.push(color)
                    }
                    // console.log(segColors)
                    this.setState({
                        segments: segments,
                        segColors: segColors,
                    });
                    //   console.log(61, segments)


                }
                else {
                    // this.CheckThisGoalError();
                }
            });
        }
        catch (error) {
            console.log(error)
        }



    }

    onFinished = async (winner) => {
        //  console.log(winner);
        this.checklogin();
        var token = localStorage.getItem('auth_token');
        if (token != null) {
            var decoded = jwt_decode(token);
            var Username = decoded.message.playerid;
            try {
                var row = {
                    wheelID: winner.id,
                    userid: Username,
                }
                await instance.post("/api/v1/postwheelspin", {
                    System: Systems,
                    userid: Username,
                    type: 1,
                    rows: row,
                }).then((res) => {
                    if (res.data.status === 200) {
                        const datas = res.data.message;
                    }
                    else {

                    }
                });
            }
            catch (error) {
                console.log(error)
            }
        }

    };
    render() {
        return (
            <>
                <div className="container p-0 x-margin-top-v3">
                    <div className="row m-0 border-shadow-title">
                        <div className="col-12 p-0 col-sm-7 col-md-7 col-lg-7 col-xl-7 mx-auto my-auto text-white-v1">
                            <div className="text-center bg-title-web">
                                <div>
                                    <div className="text-title text-white-v1 text-center">
                                        <div className="d-flex justify-content-between">
                                            <div className="text-white-v1-deposit">
                                                <a role="button" href='/'>
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width={25}
                                                        height={25}
                                                        fill="currentColor"
                                                        className="bi bi-arrow-left-circle text-color-back"
                                                        viewBox="0 0 16 16"
                                                    >
                                                        <path
                                                            fillRule="evenodd"
                                                            d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8m15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5z"
                                                        />
                                                    </svg>
                                                </a>
                                            </div>
                                            <div className="text-white-v1-deposit">
                                                <h5>คืนยอดเสีย</h5>
                                            </div>
                                            <div>{/**/}</div>
                                        </div>
                                        <hr className="mt-0" />
                                    </div>
                                </div>
                            </div>
                            <div className="p-2 bg-profile-box-overview">
                                {/**/}
                                <div className="p-2 text-left mt-4 mb-1 animated animatedFadeInUp fadeInUp credit-bl-text">
                                    เล่นวงล้อนำโชค
                                </div>
                                <div className="container-fluid p-0">
                                    <div className="container-sm m-auto p-4 pt-0">
                                        <div className="container-fluid p-4  bg-light shadow-sm ">
                                            <div className="d-flex mb-2">
                                                <img
                                                    src="/assets/icon/wheel.png"
                                                    className="align-self-center"
                                                    style={{ maxHeight: 78 }}
                                                />
                                                <div className="align-self-center" style={{color:"black"}}>
                                                    <h2 className=" ms-1 mb-0">FreeCredit </h2>
                                                    <h5 className=" ms-1">เล่นวงล้อนำโชค</h5>
                                                </div>
                                            </div>
                                            <div className="spinner" />
                                            <div className="col-lg-4 m-auto" >
                                                <div className="row">
                                                    <center>
                                                        <h5
                                                            className="text-muted"
                                                            style={{ whiteSpace: "pre-wrap", width: "100%" }}
                                                        >
                                                            ราคารอบละ : <span className="text-main">0 สิทธิ์</span>
                                                        </h5>
                                                        <a
                                                            className="btn bg-main w-100 justify-content-center align-items-center mb-2"
                                                            id="random"
                                                            style={{ borderRadius: 5,color:"black" }}
                                                        >
                                                            เริ่มสุ่ม ( 0.00 สิทธิ์ )
                                                        </a>
                                                    </center>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </>



        );
    }
}
export default App;
